import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { LoaderService } from '../../../_metronic/core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  //console.log("kar");

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService){}
ngOnInit(): void {

}

}
