// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
// RxJS
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
//import { debug } from 'util';
import { environment } from '../../../../environments/environment';
import { LayoutService } from './layout.service';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';
import * as CryptoJS from 'crypto-js';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { LoaderService } from '../services/loader.service';


/**
* More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
*/
@Injectable()
export class InterceptService implements HttpInterceptor {
	private authLocalStorageToken = `${environment.appVersion}-${environment.authTokenKey}`;
	private requests: HttpRequest<any>[] = [];

	constructor(public loaderService: LoaderService,private layoutService: LayoutService, private router: Router) { }

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
		this.layoutService.isLoading.next(this.requests.length > 0);
	}

	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		// this.checktoken();

		const tz = momentTz.tz.guess();

		this.requests.push(request);
		this.requests.forEach((element,i) => {
			if(element['body'])
			{
				if(element['body']['searchKey'] || element.url.includes('/v1/dashboard/dropdown/sites/list'))
				{
					this.loaderService.hide();
				}
				else{
					this.loaderService.show();
				}
			}
		});
		// CryptoJS.HmacSHA1(this.f.password.value,environment.appSecret).toString());

		let deviceId = localStorage.getItem('deviceId');
		deviceId = (deviceId == null) ? '' : deviceId;

		let language = localStorage.getItem('language');
		language = (language == null) ? 'en' : language;

		let logUserType = localStorage.getItem('UserType');
		logUserType = (logUserType == null) ? 'no-role' : logUserType;


		let authToken: any = localStorage.getItem(this.authLocalStorageToken);
		authToken = (authToken) ? JSON.parse(authToken) : undefined;
		console.log(authToken);
		if (authToken != null) {
			let base64Url = authToken.accessToken.split('.')[1];
			let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));

			let decryptJson = JSON.parse(jsonPayload);
			let tokenExpiry = moment.unix(decryptJson.exp); //moment(decryptJson.exp).unix();
			let timeDiff = tokenExpiry.diff(moment(), 'minutes');
			if (timeDiff < 10) {
				// this.checktoken();
			}
		}

		authToken = localStorage.getItem(this.authLocalStorageToken);
		authToken = (authToken) ? JSON.parse(authToken) : undefined;

		let requestURL = request.url.replace(environment.apiUrl, "").toString();
		requestURL = requestURL.replace(environment.contentUrl, "").toString();
		requestURL = requestURL.replace(environment.fileUrl, "").toString();
		requestURL = requestURL.replace(environment.studentUrl, "").toString();
		requestURL = requestURL.replace(environment.classUrl, "").toString();
		requestURL = requestURL.replace(environment.customerUrl, "").toString();


		let apiHash = "";
		if ((requestURL.indexOf("/users/list") > -1) || (requestURL.indexOf("/users/add") > -1) || (requestURL.indexOf("/users/internal/add") > -1) || (requestURL.indexOf("/users/delete") > -1) || (requestURL.indexOf("/users/update") > -1) || (requestURL.indexOf("/users/detail") > -1) || (requestURL.indexOf("/user/info") > -1) || (requestURL.indexOf("/users/student/add") > -1) || (requestURL.indexOf("/users/student/update") > -1)) {
			requestURL = requestURL.substring(1, requestURL.length);
		}
		let apiHashOrg = (deviceId == '') ? request.method + " /" + requestURL : request.method + " /" + requestURL + " " + deviceId;

		apiHash = CryptoJS.HmacSHA1(apiHashOrg, environment.appSecret).toString();

		let commonHeaders = {
			'api-key': environment.apiKey,
			'api-hash': apiHash
		};

		if (authToken) {
			commonHeaders['Authorization'] = `${authToken.accessToken}`;
			let userType = 'primary'; //localStorage.getItem('userType');

			let imageUrls = ["unit/add", "unit/update", "lesson/add", "lesson/update", "lesson/update/file", "diagmastery/add", "module/add", "module/update", "module/update/file", "quest/add", "quest/update", "quest/update/file",
				"activity/add", "activity/update", "activity/update/file", "expedition/add", "expedition/update", "expedition/update/file", "diagnostics/add", "diagnostics/update", "sim/add", "sim/update", "account/add", "/users/list", "users/add", "/users/detail", "users/update", "/users/delete", "site/add", "site/update", "account/update",
				"users/student/add", "users/student/update", "rubric/add", "rubric/update", "rubric/bulk/add", "rubric/category/update", "rubric/section/delete", "material/add", "material/update", 'users/internal/add', 'update/profile/image', "assessment/add", "student/import", "question/add", "question/update", "account/remove/image", "dashboard/loyalty/touch/add", "dashboard/loyalty/touch/edit", 'dashboard/site/action/add', 'dashboard/site/action/edit', 'dashboard/site/doc/add', 'dashboard/site/doc/edit', 'dashboard/site/activity/add', 'dashboard/site/activity/edit','dashboard/site/loyalty/edit','dashboard/site/loyalty/add',
				'dashboard/site/loyalty/copy','dashboard/site/contact/add','dashboard/site/contact/edit','dashboard/roadmap/add','dashboard/roadmap/edit','dashboard/site/roadmap/add','dashboard/site/roadmap/edit','dashboard/site/obs/add','dashboard/site/user/edit','dashboard/site/user/add','publication/add','publication/edit','publication/category/edit'];

			if (imageUrls.indexOf(requestURL) == -1) {
				commonHeaders['Content-Type'] = 'application/json';
				if (request.method == 'POST') {
					if (userType == 'primary') {
						request = request.clone({ body: { ...request.body, deviceId: deviceId, language: language, timeZone: tz, userType: logUserType } });
					}
				}
			}
		}
		else {
			request = request.clone({ body: { ...request.body, deviceId: deviceId, language: language, timeZone: tz, userType: logUserType } });
		}

		request = request.clone({
			setHeaders: commonHeaders
		});

		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse && event.status === 200)
					{
				       this.loaderService.hide();
				  }
					if (event instanceof HttpResponse)
					{
						this.removeRequest(request);
					}

					this.removeRequest(request);
				},
				error => {
					this.loaderService.hide();
					this.removeRequest(request);

					if (error.status == 409 || error.status == 403 || error.status == 401) {

					}
					else if (error['error']['error_msg'] == "Token expired") {

						return throwError(error);
					}

				}
			)
		);
	}

	checktoken() {
		var that = this;
		firebase.auth().onAuthStateChanged(function(user) {
			if (user) {
				user.getIdToken(true).then(function(idToken) {

					let authParam = { accessToken: idToken, refreshToken: '', expiresIn: '' };
					//let authParam = { accessToken: user.uid, refreshToken: '', expiresIn: '' };
					localStorage.setItem(that.authLocalStorageToken, JSON.stringify(authParam));
				});
			}
		});
	}
}
