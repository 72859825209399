// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v2.0.0',
  authTokenKey: 'authZb8aWJcf7UE2',
  appVersion2: 'v2.0.0_2',
  authTokenKey2: 'authZb8aWJcf7UE2',
  isMockEnabled: true,

  apiUrl: 'https://devproxy.staracademyapp.com/auth-api/v1/',
  customerUrl:'https://devcustomer.staracademyapp.com/v1/',
  inventoryUrl:'https://devinventoryapi.staracademyapp.com/v1/',
  contentUrl:'https://devproxy.staracademyapp.com/content-api/v1/',
  fileUrl:'https://devproxy.staracademyapp.com/file-upload-api/v1/',
  studentUrl :'https://devproxy.staracademyapp.com/students-api/v1/',
  classUrl:'https://devproxy.staracademyapp.com/classes-api/v1/',
  jreUrl:'https://jredev.staracademyapp.com/',
  reportUrl:'https://devproxy.staracademyapp.com/reports-api/v1/',

  trainingUrl:'https://devtraining.nolaedu.net/training-api/v1/',
  trainingAuthUrl:'https://devtraining.nolaedu.net/auth-api/v1/',

  currentURL:'https://devcustomermanager.staracademyapp.com/',

  apiKey: 'd70df137d049ec833c84d2246a07dc9035fb82fc',
  agmKey:'AIzaSyCcAEbf4Emf9FAUZfd0odvcAjiClSs8KhQ',

  fireBaseKey: 'AIzaSyCcAEbf4Emf9FAUZfd0odvcAjiClSs8KhQ',
  fireBaseAuthDomain: 'staracademy-development.firebaseapp.com',
  databaseURL:'https://staracademy-development-default-rtdb.firebaseio.com/',
  projectId:'staracademy-development',
  appSecret: 'HjjeASAheQFavWVYJu3GuYcH7KmWavdcWEP25Kpm',
  emailDomain: 'staracademyapp.com',
  criptoKey: "dMz3cFJ2HdvmEKE6",
  criptoIv: "ScN45U9EsJxmFzfK"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
