import { Injectable,ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {

    isLoading = new Subject<boolean>();
    show() {
      //alert("in");
        this.isLoading.next(true);
    }
    hide() {
      //alert("hide");
        this.isLoading.next(false);
    }
}
