import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtml } from './safehtml';



@NgModule({
  declarations: [ SanitizeHtml ],
  imports: [
    CommonModule
  ],
   exports:[SanitizeHtml] // <---
})
export class PipeModule { }
