import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppResponse } from '../_models/app-response.model';
import { AccountListResp } from '../_models/accountdis.model';
import { SchoolListResp } from '../_models/school.model';
import { GroupListResp } from '../_models/group.model';
import { IndividualListResp } from '../_models/individual.model';
import { TeachersListResp } from '../_models/teachers.model';
import { DyadListResp } from '../_models/dyad.model';
import { LockedstudentListResp } from '../_models/lockedstudents.model';

import { LicenseListResp } from '../_models/licenses.model';
import { AdministratorListResp } from '../_models/administrators.model';
import { LoaderService } from '../../../_metronic/core/services/loader.service';



const API_URL = environment.apiUrl;
const CUSTOMER_URL = environment.customerUrl;
const CONST_URL = environment.contentUrl;
const FILE_URL = environment.fileUrl;
const STUDENT_URL = environment.studentUrl;
const CLASS_URL = environment.classUrl;

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	// private fields
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private authLocalStorageToken = `${environment.appVersion}-${environment.authTokenKey}`;

	// public fields
	currentUser$: Observable<UserModel>;
	isLoading$: Observable<boolean>;
	currentUserSubject: BehaviorSubject<UserModel>;
	isLoadingSubject: BehaviorSubject<boolean>;

	private messageSource = new BehaviorSubject({ from: 'default', id: '' });
	currentMessage = this.messageSource.asObservable();

	private updateUserImage = new BehaviorSubject('defaultimg');
	currentApprovalStageMessage = this.updateUserImage.asObservable();

	libraryMenusauth: any = {};
	libraryMenuslist: any = {};
	userMenu: any = {};
	Rolelist: any;
	get currentUserValue(): UserModel {
		return this.currentUserSubject.value;
	}


	set currentUserValue(user: UserModel) {
		this.currentUserSubject.next(user);
	}

	constructor(
		public authHttpService: AuthHTTPService,
		public router: Router, public http: HttpClient,
		public loaderService: LoaderService
	) {
		this.isLoadingSubject = new BehaviorSubject<boolean>(false);
		this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
		this.currentUser$ = this.currentUserSubject.asObservable();
		this.isLoading$ = this.isLoadingSubject.asObservable();
		//const subscr = this.getUserByToken().subscribe();
		//this.unsubscribe.push(subscr);
		this.loaderService.hide();
	}


	changeMessage(message: any) {
		this.messageSource.next(message)
	}

	updateUserImages(message: string) {
		this.updateUserImage.next(message)
	}

	getIpAddress(): Observable<any> {
		this.loaderService.hide();
		this.isLoadingSubject.next(true);
		return this.authHttpService.getIpAddress().pipe(
			map((resp: AppResponse) => {
				//const result = resp.result;
				return resp;
			}),
			catchError((err) => {
				return of(undefined);
			}),
			finalize(() => this.isLoadingSubject.next(false))
		);
	}
	deviceRegister(params: any) {
		this.loaderService.hide();
		return this.http.post<AppResponse>(API_URL + "device/register", params).pipe(catchError(this.handleError));
	}

	login(deviceinfo): Observable<any> {
		this.isLoadingSubject.next(true);
		localStorage.setItem('UserType', "no-role");
		return this.authHttpService.login(deviceinfo).pipe(
			map((loginResp: any) => {
				let result = loginResp;
				console.log(result);
				let authParam = { accessToken: result.result.accessToken, refreshToken: '', expiresIn: '' };
				this.setAuthFromLocalStorage(authParam);
				localStorage.setItem('accessToken',result.result.accessToken);               
				
                
				localStorage.setItem('UserLogDetails', loginResp.result.user.fullName);
				localStorage.setItem('AccCurUserId', loginResp.result.user.id);
				localStorage.setItem('UserType', loginResp.result.user.role.roleType);
				localStorage.setItem('RoleId', loginResp.result.user.role.id);
				if (loginResp.result.user.accounts != '') {
					localStorage.setItem('AccDetailsID', loginResp.result.user.accounts[0].id);
					localStorage.setItem('AccName', loginResp.result.user.accounts[0].accountName);
					localStorage.setItem('AccCatType', loginResp.result.user.accounts[0].categoryType);

				}

				if (loginResp.result.user.sites != '') {
					localStorage.setItem('Siteid', loginResp.result.user.sites[0].id);
					localStorage.setItem('accountId', loginResp.result.user.sites[0].accountId);
				}

				// localStorage.setItem('menus', JSON.stringify(loginResp.result.menus));
				let menus =
					[{ id: "1", menuIconCss: "dashboard", menuName: "Home", primary: 1, routeName: "home" },
					{ id: "2", menuIconCss: "widgets", menuName: "Sites", primary: 1, routeName: "sites" },
					{ id: "3", menuIconCss: "dashboard", menuName: "Reports", primary: 1, routeName: "reports" },
					{ id: "4", menuIconCss: "dashboard", menuName: "Road Map", primary: 1, routeName: "loyaltytouches" },
					{ id: "5", menuIconCss: "content_copy", menuName: "Curriculum Builder", primary: 1, routeName: "curriculumbuilder" },
					{ id: "6", menuIconCss: "dashboard", menuName: "Internal users", primary: 1, routeName: "internalusers" },
					{ id: "7", menuIconCss: "dashboard", menuName: "Publication", primary: 1, routeName: "publication" },
					{ id: "8", menuIconCss: "dashboard", menuName: "Help", primary: 1, routeName: "help" },
					// { id: "8", menuIconCss: "speed", menuName: "Teamperfomance", primary: 1, routeName: "teamperfomance" }
				];
				localStorage.setItem('menus', JSON.stringify(menus));
				
				return true;
			}),
			switchMap(() => this.getUserByToken()),
			catchError(this.handleError),
			finalize(() => this.isLoadingSubject.next(false))
		);
	}

	logout() {
		localStorage.removeItem("AccCurUserId");
		localStorage.removeItem("UserLogDetails");
		localStorage.removeItem("RoleId");
		localStorage.removeItem("Siteid");
		localStorage.removeItem("accountId");
		localStorage.removeItem("UserType");
		localStorage.removeItem("AccDetailsID");
		localStorage.removeItem("AccName");
		localStorage.removeItem("AccCatType");
		localStorage.removeItem(this.authLocalStorageToken);
		localStorage.removeItem('menus');
		localStorage.removeItem('selectedval');
		this.router.navigate(['/auth/login'], {
			queryParams: {},
		});
	}

	customerCall(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = CUSTOMER_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	apiCall(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = API_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	contentCall(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = CONST_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	studentUrl(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = STUDENT_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	classUrl(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = CLASS_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	addandupdatelicense(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = API_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	fileCall(postParam: any, endPoint: string): Observable<AppResponse> {
		let finalURL = FILE_URL + endPoint;
		return this.http.post<AppResponse>(finalURL, postParam).pipe(catchError(this.handleError));
	}

	getUserByToken(): Observable<UserModel> {
		// const auth = this.getAuthFromLocalStorage();
		// if (!auth || !auth.accessToken) {
		// 	return of(undefined);
		// }
		this.isLoadingSubject.next(true);
		return this.authHttpService.getUserByToken({}).pipe(
			map((userResp: AppResponse) => {
				if (userResp) {
					this.currentUserSubject = new BehaviorSubject<UserModel>(userResp.result.user);
					let menus =
						[{ id: "1", menuIconCss: "dashboard", menuName: "Home", primary: 1, routeName: "home" },
						{ id: "2", menuIconCss: "widgets", menuName: "Sites", primary: 1, routeName: "sites" },
						{ id: "9", menuIconCss: "school", menuName: "Standards", primary: 1, routeName: "standards" },
						{ id: "3", menuIconCss: "insert_chart", menuName: "Reports", primary: 1, routeName: "reports" },
						{ id: "4", menuIconCss: "touch_app", menuName: "Road Map", primary: 1, routeName: "loyaltytouches" },
						{ id: "8", menuIconCss: "network_check", menuName: "Team Performance", primary: 1, routeName: "teamperfomance" },
						{ id: "5", menuIconCss: "collections_bookmark", menuName: "Curriculum Builder", primary: 1, routeName: "curriculumbuilder" },
						{ id: "6", menuIconCss: "work", menuName: "Internal users", primary: 1, routeName: "internalusers" },
						{ id: "7", menuIconCss: "dashboard", menuName: "Publication", primary: 1, routeName: "publication" },
						{ id: "8", menuIconCss: "help", menuName: "Help", primary: 1, routeName: "help" },
					];

					localStorage.setItem('menus', JSON.stringify(menus));
					//localStorage.setItem('menus', JSON.stringify(userResp.result.menus));
					localStorage.setItem('UserLogDetails', userResp.result.user.fullName);
					if (userResp.result.user.accounts != '') {
						localStorage.setItem('AccDetailsID', userResp.result.user.accounts[0].id);
						localStorage.setItem('AccName', userResp.result.user.accounts[0].accountName);
						localStorage.setItem('AccCatType', userResp.result.user.accounts[0].categoryType);
					}
					localStorage.setItem('UserType', userResp.result.user.role.roleType);
					this.userMenu = JSON.parse(localStorage.getItem('menus'));


				} else {
					this.logout();
				}
				return userResp.data;
			}),
			finalize(() => this.isLoadingSubject.next(false))
		);
	}

	findlicenses(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<LicenseListResp> {
		let finalURL = API_URL + 'license/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;

		return this.http.post<LicenseListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findDyad(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<DyadListResp> {
		let finalURL = API_URL + 'license/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;

		return this.http.post<DyadListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findAdministrator(filter: any, sortOrder, sortField, pageNumber, pageSize): Observable<any> {
		let finalURL = API_URL + '/users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	//dashboard list
	finduserfordashboard(filter: any, sortOrder, sortField, pageNumber, pageSize): Observable<AccountListResp> {
		let finalURL = API_URL + '/users/list';
		filter.categoryType = ['district'];
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;

		return this.http.post<AccountListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}
	//Locked students list
	finduserforLockedstudents(filter: any, sortOrder, sortField, pageNumber, pageSize): Observable<LockedstudentListResp> {
		let finalURL = API_URL + 'class/locked/students';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;

		return this.http.post<LockedstudentListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}
	// need create new user then login
	finddistrict(filter: any, sortOrder, sortField, pageNumber, pageSize): Observable<AccountListResp> {
		let finalURL = API_URL + 'account/list';
		filter.categoryType = ['district'];
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;


		return this.http.post<AccountListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	finddistrictSchool(filter: any, sortOrder, sortField, pageNumber, pageSize): Observable<AccountListResp> {
		let finalURL = API_URL + 'site/list';
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.categoryType = ['school'];
		filter.sort = sortOrder;
		filter.sortKey = sortField;
		return this.http.post<AccountListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	finddistrictGroup(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<AccountListResp> {
		let finalURL = API_URL + 'site/list';
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.categoryType = ['group'];
		filter.sort = sortOrder;
		filter.sortKey = sortField;
		return this.http.post<AccountListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	finddistrictIndividual(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<AccountListResp> {
		let finalURL = API_URL + 'site/list';
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.categoryType = ['individual'];
		filter.sort = sortOrder;
		filter.sortKey = sortField;
		return this.http.post<AccountListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findschool(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<SchoolListResp> {
		let finalURL = API_URL + 'account/list';
		filter.categoryType = ['school'];
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.sortKey = sortField;
		filter.sort = sortOrder;

		return this.http.post<SchoolListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}
	findgroup(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<GroupListResp> {
		let finalURL = API_URL + 'account/list';
		filter.categoryType = ['group'];
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.sortKey = sortField;
		filter.sort = sortOrder;

		return this.http.post<GroupListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findCorePrdouct(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<GroupListResp> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/standard/product/correlations/list';
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.sortKey = sortField;
		filter.sort = sortOrder;
		return this.http.post<GroupListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findviewCorePrdouct(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<GroupListResp> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/product/view/standard/correlations/list';
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.sortKey = sortField;
		filter.sort = sortOrder;
		return this.http.post<GroupListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findindividual(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<IndividualListResp> {
		let finalURL = API_URL + 'account/list';
		filter.categoryType = ['individual'];
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.sortKey = sortField;
		filter.sort = sortOrder;

		return this.http.post<IndividualListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	findStudents(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = API_URL + '/users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.roleKey = ['student'];
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findProducts(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/dropdown/products/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	findProductscbs(builderId,filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 25): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/curriculum/products';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.builderId = builderId;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	findeditProducts(builderId,filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 25): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/curriculum/products';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.builderId = builderId;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findCurriculumbuilders(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/curriculum/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	findSites(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findcbSites(builderId,filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/curriculum/sites';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.builderId = builderId;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findcdeditSites(builderId,filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/curriculum/sites/edit/list';
		filter.sort = sortOrder;
		if(from != 'addSites'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		if(builderId != null){
			filter.builderId = builderId;
		}
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findcbProducts(builderId,filter: any, sortOrder, sortField, pageNumber, pageSize,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/curriculum/products/edit/list';
		filter.sort = sortOrder;
		if(from != 'addprod'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		if(builderId != null){
			filter.builderId = builderId;
		}
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findBfrproducts(bfrId,stdIds,filter: any, sortOrder, sortField, pageNumber, pageSize,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/bestfit/standard/products';
		filter.sort = sortOrder;
		if(from != 'addprod'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.bfId = bfrId;
		if(stdIds != null){
			filter.standards = stdIds;
		}
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findBfradditionalproducts(productIds,filter: any, sortOrder, sortField, pageNumber, pageSize): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/bestfit/noaproducts/list';
		filter.sort = sortOrder;
		// filter.page = pageNumber;
		// filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.productIds = productIds;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findstandardProducts(stdId,filter: any, sortOrder, sortField, pageNumber=0, pageSize=25,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/standard/products/edit/list';
		filter.sort = sortOrder;
		if(from != 'addstd'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		if(stdId != ""){
			filter.stdId = stdId;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findviewstandardProducts(stdId,filter: any, sortOrder, sortField, pageNumber, pageSize,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/standard/products';
		filter.sort = sortOrder;
		if(from != 'addstd'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		if(stdId != ""){
			filter.stdId = stdId;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findviewstdaddProducts(stdId,filter: any, sortOrder, sortField, pageNumber, pageSize,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/standard/products/edit/list';
		filter.sort = sortOrder;
		if(from != 'addprod'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		if(stdId != ""){
			filter.stdId = stdId;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findBfrstandards(bfId,stdId,filter: any, sortOrder, sortField, pageNumber, pageSize,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/bestfit/standard/edit/list';
		filter.sort = sortOrder;
		if(from != 'allStd'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		if(stdId != null){
			filter.stdId = stdId;
			filter.bfId = bfId;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findBfrAssstandards(bfrId,stdId,filter: any, sortOrder, sortField, pageNumber, pageSize,from): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/bestfit/noastandards';
		filter.sort = sortOrder;
		if(from != 'addprod'){
			filter.page = pageNumber;
			filter.limit = pageSize;
		}
		if(stdId != null){
			filter.standards = stdId;
			filter.bfId = bfrId;
		}
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findPrrs(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/prr/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findBestfit(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/bestfit/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findCorrelation(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/correlation/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}



	findStandards(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/standard/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findContacts(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let conAccId = localStorage.getItem("ConAccId");
		if(conAccId)
		{
			conAccId = conAccId;
		}
		else{
			conAccId = '';
		}

		let conSiteId = localStorage.getItem("ConSiteId");
		if(conSiteId)
		{
			conSiteId = conSiteId;
		}
		else{
			conSiteId = '';
		}
		let finalURL = CUSTOMER_URL + 'dashboard/all/contacts/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.accountId=conAccId;
		filter.siteId=conSiteId;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findmonthReports(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/monthly/reports/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findViewMonthReports(id:any,filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/monthly/sites/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.monthId = id;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}




	findTouches(phaseId, filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/roadmap/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.phaseId = phaseId;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}



	findSitecontacts(schid, filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = schid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}





	findStudentsschool(schid, filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = API_URL + '/users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = schid;
		filter.roleKey = ['student'];
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findGradebook(classId, filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = API_URL + 'class/grade/book';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.classId = classId;
		//filter.roleKey = ['student'];
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}



	findTeachers(filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<TeachersListResp> {

		let finalURL = CUSTOMER_URL + 'dashboard/site/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		if(filter.contactType == 'district'){
			filter.categoryType = ['district'];
		}else{
			filter.categoryType = ['school', 'group', 'individual'];
		}
		return this.http.post<TeachersListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findcontract(filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<TeachersListResp> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/contract/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<TeachersListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findTeachersAdminList(filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<TeachersListResp> {
		let Usertype = localStorage.getItem('UserType');
		//alert(Usertype);
		let finalURL = API_URL + '/users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.roleKey = [Usertype];
		return this.http.post<TeachersListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findTeachersschool(schid, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<TeachersListResp> {
		let finalURL = API_URL + '/users/list';
		//let finalURL = CUSTOMER_URL + 'dashboard/site/contact/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;

		if(localStorage.getItem('account_id') != "" && localStorage.getItem('contactType') == 'district'){
			filter.roleKey = ["district-admin"];
			filter.accountId =  localStorage.getItem("account_id") == "" ? null : localStorage.getItem("account_id");
		}else if(localStorage.getItem('account_id') != "" && localStorage.getItem('contactType') == 'site'){
			filter.siteId = schid;
			filter.roleKey = ["teacher", "teacher-admin", "school-admin"]
		}else if(localStorage.getItem('account_id') == ''){
			filter.accountId = schid;
			filter.roleKey = ["school-admin","teacher-admin","teacher","group-admin","individual","nola-admin","salmember"]
		}
		else{
			filter.siteId = schid;
			filter.roleKey = ["school-admin","teacher-admin","teacher","group-admin","individual","nola-admin","salmember"]
		}
		return this.http.post<TeachersListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findDistrict(schid, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<TeachersListResp> {
		let finalURL = API_URL + '/users/list';
		//let finalURL = CUSTOMER_URL + 'dashboard/site/contact/list';
		let accountId =  localStorage.getItem("account_id") == "" ? null : localStorage.getItem("account_id");
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.roleKey = ["district-admin"];
		filter.accountId = accountId;
		return this.http.post<TeachersListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	findforduserlist(schid, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<TeachersListResp> {
		let finalURL = API_URL + '/users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.roleKey = ["district-admin","school-admin","teacher-admin","teacher","group-admin","individual","nola-admin","salmember"];
		return this.http.post<TeachersListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}



	findRoles(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<IndividualListResp> {
		let UserType = localStorage.getItem('UserType');

		if ((UserType == 'super-admin') || (UserType == 'nola-admin') || (UserType == 'district-admin')) {
			this.Rolelist = ["district", "school", "teacher", "individual", "group"];
		}
		else if (UserType == 'school-admin') {
			this.Rolelist = ["school", "teacher"];
		}
		else if (UserType == 'teacher') {
			this.Rolelist = ["teacher"];
		}

		let finalURL = API_URL + 'role/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.roleType = this.Rolelist;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<IndividualListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	findRolesAdmin(filter: any, sortOrder, sortField, pageNumber = 0, pageSize = 10): Observable<IndividualListResp> {
		let Usertype = localStorage.getItem('UserType');

		let finalURL = API_URL + 'role/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.roleType = ['admin'];
		return this.http.post<IndividualListResp>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}




	findAdmin(filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = API_URL + '/users/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.roleKey = ["district-admin", "school-admin", "group-admin", "individual"];
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	findAdminschool(schid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/report/site/prr/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = schid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	actionlist(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/action/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	documnentlist(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/doc/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	activitylist(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/activity/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	surveylist(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/reflection/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	fordlist(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/ford/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	observationlist(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/obs/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	todolist( filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/todo/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}


	emailList(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/email/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}

	siteProfileList(siteid: any, filter: any, sortOrder = 'asc', sortField = 'fullName', pageNumber = 0, pageSize = 10): Observable<any> {
		let finalURL = CUSTOMER_URL + 'dashboard/site/profile/list';
		filter.sort = sortOrder;
		filter.page = pageNumber;
		filter.limit = pageSize;
		filter.searchKey = filter.searchKey;
		filter.sortKey = sortField;
		filter.siteId = siteid;
		return this.http.post<any>(finalURL, filter).pipe(map(res => res)).pipe(catchError(this.handleError));
	}






	forgotPassword(email: string): Observable<boolean> {
		this.isLoadingSubject.next(true);
		return this.authHttpService
			.forgotPassword(email)
			.pipe(finalize(() => this.isLoadingSubject.next(false)));
	}

	// private methods
	setAuthFromLocalStorage(auth: any): boolean {	
			if (auth && auth.accessToken) {
				console.log(auth)
				localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
				localStorage.setItem('accessToken',auth.accessToken);
				return true;
			}
			return false;	
	}  

	private getAuthFromLocalStorage(): AuthModel {
		try {
			const authData = JSON.parse(
				localStorage.getItem(this.authLocalStorageToken)
			);
			return authData;
		} catch (error) {
			return undefined;
		}
	}
	private handleError(error: HttpErrorResponse) {
		let errorMessage = 'Something bad happened; please try again later.';

		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			errorMessage = error.error.error;

		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.result.error}`);
			errorMessage = error.error.result.error;
		}
		// Return an observable with a user-facing error message.
		return throwError(errorMessage);
	}

	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}

	public getrole(menuNames: string, key: string, param: string = null): number {
		//alert(menuNames);
		let libraryMenu = this.userMenu.find(x => x.routeName === menuNames);
		if (libraryMenu != null) {
			this.libraryMenusauth = libraryMenu.subMenu;

			const menuAccess = this.libraryMenusauth;

			let returnvalue = 0;
			if (menuAccess && this.getIndex(key, menuAccess) >= 0) {
				returnvalue = JSON.stringify(menuAccess[this.getIndex(key, menuAccess)].permissions) ===
					JSON.stringify({
						view: 0,
						edit: 0,
						delete: 0
					}) ? 0 : param === null ? 1 : menuAccess[this.getIndex(key, menuAccess)].permissions[param];

			}
			return returnvalue;
			//return (menuAccess && this.getIndex(key,menuAccess) >= 0) ? menuAccess[this.getIndex(key,menuAccess)].access[param] : 0;
		}

	}

	public getIndex(key: string, menuAccess: any): number {
		return menuAccess.findIndex(data => {

			return data.routeName === key;
		});
	}

}
