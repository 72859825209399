import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { InterceptService } from './_metronic/core/services/intercept.service';
// #fake-end#
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { KatexModule } from 'ng-katex';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipeModule } from './modules/pipe/pipe.module';
//import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { LoaderService } from './_metronic/core/services/loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './components/shared/loader/loader.component';
import {  MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';



function appInitializer(authService: AuthService) {
	return () => {
		return new Promise((resolve) => {
			authService.getUserByToken().subscribe().add(resolve);
		});
	};
}
const config = {
	apiKey: environment.fireBaseKey,
	authDomain: environment.fireBaseAuthDomain,
};
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import * as firebase from 'firebase/app';
import 'firebase/auth';




FullCalendarModule.registerPlugins([
	interactionPlugin,
	dayGridPlugin
]);

firebase.initializeApp(config);

@NgModule({
	declarations: [AppComponent,LoaderComponent],
	imports: [
		BrowserModule, FormsModule,MatProgressSpinnerModule,
		ReactiveFormsModule, FullCalendarModule, NgxSkeletonLoaderModule.forRoot(), ToastrModule.forRoot(),
		BrowserAnimationsModule,
		MatTooltipModule,
		SplashScreenModule,
		TranslateModule.forRoot(),
		HighlightModule,
		ClipboardModule, PdfViewerModule,
		// #fake-start#
		environment.isMockEnabled
			? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
				passThruUnknownUrl: true,
				dataEncapsulation: false,
			})
			: [],
		// #fake-end#
		AppRoutingModule,
		InlineSVGModule.forRoot(),
		NgbModule, AngularFireModule.initializeApp(config),
		AngularFireAuthModule,
		NgScormPlayerModule.forRoot({ debug: true }),
		KatexModule,
		AngularEditorModule,
		HttpClientModule,
		NgSelectModule,
		MatExpansionModule,
		PipeModule,
		AgmOverlays,
		MatTooltipModule,
		DragDropModule
	],
	providers: [
		LoaderService,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			multi: true,
			deps: [AuthService],
		},
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},

		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					xml: () => import('highlight.js/lib/languages/xml'),
					typescript: () => import('highlight.js/lib/languages/typescript'),
					scss: () => import('highlight.js/lib/languages/scss'),
					json: () => import('highlight.js/lib/languages/json')
				},
			},
		},
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
