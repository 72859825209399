import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { AppResponse } from '../../_models/app-response.model';
import * as CryptoJS from 'crypto-js';

const API_USERS_URL = `${environment.apiUrl}/users`;
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  apihash: any = [];
  deviceId: string='';

  constructor(private http: HttpClient) { }
  getIpAddress() {
    //let forapihash = 'post' + '' + API_URL + ''+ this.deviceId;
    //this.apihash = CryptoJS.HmacSHA1(forapihash, environment.appSecret);
    //localStorage.setItem('api-hash', this.apihash);

    return this.http.post<AppResponse>(API_URL + "get/ip", {});
  }

  // public methods
  login(loginParam:any): Observable<any> {
    return this.http.post<AuthModel>(API_URL + "log/in", loginParam);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(detailParam:any): Observable<AppResponse> {
     return this.http.post<AppResponse>(API_URL + "user/info", detailParam);
  }}
