import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AuthGuard } from './modules/auth/_services/auth.guard';
import {MatExpansionModule} from '@angular/material/expansion';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
 //  {
 //   path: 'userprofile',
 //   loadChildren: () =>
 //     import('./modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
 // },
  {
    path: 'user-profile',
    loadChildren: () =>
      import('./modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: '',
     canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [FormsModule,
  ReactiveFormsModule,RouterModule.forRoot(routes),MatExpansionModule],
  exports: [RouterModule,MatExpansionModule],
})
export class AppRoutingModule { }
